import { decamelizeKeys } from 'humps';

import * as Amplitude from 'services/amplitude';

const shoppingList = {
  mealAdded: 'shopping_list.meal_added',
  mealRemoved: 'shopping_list.meal_removed',
  servingsChanged: 'shopping_list.servings_changed',
  ingredientRemoved: 'shopping_list.ingredient_removed',
  ingredientSelected: 'shopping_list.ingredient_selected',
  copied: 'shopping_list.export.copied',
  emailed: 'shopping_list.export.emailed',
  edited: 'shopping_list.export.edited',
  viewed: 'shopping_list.viewed',
  exportViewed: 'shopping_list.export.viewed',
  cartCreated: 'shopping_list.cart_created',
};

const cookbook = {
  recipeDetailsViewed: 'my_cookbook.recipe_detail.viewed',
  onboardingModalViewed: 'my_cookbook.onboarding_modal.viewed',
  onboardingModalPrev: 'my_cookbook.onboarding_modal.prev_step',
  onboardingModalNext: 'my_cookbook.onboarding_modal.next_step',
};

const meal = {
  nutritionViewed: 'meal.nutrition.viewed',
};

const betaBox = {
  landingViewed: 'beta_box_landing.dashboard.viewed',
  optOutClicked: 'beta_box_dashboard.opt_out.clicked',
  optInClicked: 'beta_box_dashboard.opt_in.clicked',
  mealCardClicked: 'beta_box_dashboard.meal_overview.clicked',
};

const messageCenter = {
  drawerShown: 'message_drawer.opened',
  appstoreClicked: 'appstore.clicked',
};

const orderHistory = {
  container: 'account.order_history.clicked',
  order: 'account.order_history.order.viewed',
};

const holiday = {
  searched: 'holiday_lander.searched',
  bannerListClicked: 'holiday_lander.banner_list.clicked',
  bannerMapClicked: 'holiday_lander.banner_map.clicked',
};

const mealCard = {
  customizeItClicked: 'mealCard.customizeIt.clicked',
};

const mealDetails = {
  addAndUnskipClicked: 'meal_details.add_and_unskip.clicked',
};

const tracking = {
  trackPackageClicked: 'track_package.viewed',
};

const pause = {
  pauseClicked: 'account.pause.viewed',
  pauseReasons: 'account.pause_reason_selection.viewed',
  scheduleReactivationClicked: 'accountpause_schedulereactivation.clicked',
  supportRequestClicked: 'accountpause_contactsupport.clicked',
  switchPlanClicked: 'accountpause_switchplan.clicked',
  abandonPauseClicked: 'pause.abandon_first_step.clicked',
  deliveryFrequencyChangeClicked: 'accountpause_changeDelieveryFrequency.clicked',
  creditOfferModalViewed: 'pause.credit_option_modal.viewed',
  creditOfferModalAccepted: 'pause.interferer_option.clicked',
  inlineDeflectorsClicked: 'pause.interferer_option.clicked',
};

const popUp = {
  aLaCarteShow: 'alacarte.toast.show',
  aLaCarteDismiss: 'alacarte.toast.dismissed',
};

const modal = {
  optInDismissed: 'optIn.modal.dismissed',
  optInSubscribed: 'optIn.modal_subscribed.clicked',
  addonDismissed: 'addon.modal.dismissed',
  addonSubscribed: 'addon.modal_subscribed.clicked',
};

const menuSearch = {
  recipeSearchClicked: 'recipesearch.clicked',
  recipeSearchSignupClicked: 'recipesearch_signup.clicked',
  recipeSearchNoResultsViewed: 'recipesearch_no_results.viewed',
  recipeSearchChickenClicked: 'recipesearch_chicken.clicked',
  recipeSearchSteakStripsClicked: 'recipesearch_steakstrips.clicked',
  recipeSearchTacosClicked: 'recipesearch_tacos.clicked',
  recipeSearchRiceBowlClicked: 'recipesearch_ricebowls.clicked',
  recipeSearchPastResultsViewed: 'recipesearch_past_results.viewed',
  recipeSearchMealCardClicked: 'recipesearch_meal_card.clicked',
  recipeSearchPageClicked: 'recipesearch.next_page_clicked',
};

const extras = {
  viewed: 'extra_message.viewed',
  addMealClicked: 'extra_message.add_meal.clicked',
  removeMealClicked: 'extra_message.remove_meal.clicked',
  seeMoreClicked: 'extra_message.see_more.clicked',
  dismissed: 'extra_message.dismissed',
};

const quickFilters = {
  clicked: 'quick_filter.clicked',
};

const mealsNavigation = {
  clicked: 'dashboard.navigation.clicked',
};

const accountSettings = {
  completeSignupClicked: 'complete_signup.clicked',
};

const subscriptionForm = {
  planViewed: 'accountsettings_plan.viewed',
  planChanged: 'accountsettings_plan.changed',
};

const confirmationModal = {
  complete: 'confirmation.complete.clicked',
  close: 'confirmation.close.clicked',
  nextWeek: 'confirmation.next_week.clicked',
};

const signUp = {
  homechef: {
    intro: {
      viewed: 'new_signup_intro.viewed',
      submitted: 'new_signup_intro.submitted',
      signInClicked: 'new_signup_intro_signin.clicked',
      planClicked: 'new_signup_intro_plan.clicked',
      experienceClicked: 'new_signup_intro_experience.clicked',
    },
    plans: {
      viewed: 'family_funnel.plans.viewed',
      submitted: 'family_funnel.plans.submitted',
    },
    preferences: {
      viewed: 'new_signup_preference.viewed',
      submitted: 'new_signup_preference.submitted',
      greatestHits: 'new_signup_preference_greatesthits.clicked',
      easyPrepMeals: 'new_signup_preference_easyprep.clicked',
      familyMeals: 'new_signup_preference_family.clicked',
      freshStart: 'new_signup_preference_freshstart.clicked ',
    },
    preferencesDietary: {
      viewed: 'preference_dietary.viewed',
      submitted: 'preference_dietary.submitted',
    },
    preferencesAvoidances: {
      viewed: 'preference_avoidances.viewed',
      submitted: 'preference_avoidances.submitted',
    },
    boxRecommender: {
      boxPeopleViewed: 'standard_funnel.box_people.viewed',
      boxPeopleSubmitted: 'standard_funnel.box_people.submitted',
      boxMealsViewed: 'standard_funnel.box_meals.viewed',
      boxMealsSubmitted: 'standard_funnel.box_meals.submitted',
      boxDaysViewed: 'standard_funnel.box_days.viewed',
      boxDaysSubmitted: 'standard_funnel.box_days.submitted',
      boxPeopleViewedFamily: 'family_funnel.box_people.viewed',
      boxPeopleSubmittedFamily: 'family_funnel.box_people.submitted',
      boxMealsViewedFamily: 'family_funnel.box_meals.viewed',
      boxMealsSubmittedFamily: 'family_funnel.box_meals.submitted',
      boxDaysViewedFamily: 'family_funnel.box_days.viewed',
      boxDaysSubmittedFamily: 'family_funnel.box_days.submitted',
    },
    box: {
      viewed: 'new_signup_box.viewed',
      submitted: 'new_signup_box.submitted',
    },
    promo: {
      newSignUpPromoViewed: 'new_signup_promo.viewed',
      newSignUpPromoSubmitted: 'new_signup_promo.submitted',
      newSignUpPromoApplied: 'new_signup_promo.applied',
      newSignUpPromoChanged: 'new_signup_promo.changed',
      newSignUpSecretSaleClaimed: 'new_signup_secretsale.claimed',
      newSignUpIdmeClicked: 'new_signup_idme.clicked',
    },
    checkout: {
      viewed: 'new_signup_billing.viewed',
      paypalClicked: 'new_signup_paypal.clicked',
      applepayClicked: 'new_signup_applepay.clicked',
      submitted: 'new_signup_billing_cta.submitted',
    },
    thankYou: {
      viewed: 'new_signup_thankyou.viewed',
      submitted: 'new_signup_thankyou.submitted',
      referralSent: 'new_signup_thankyou_referral.sent',
      referralClicked: 'new_signup_thankyou_referrallink.copied',
    },
  },
  tempo: {
    intro: {
      viewed: 'tempo_signup_intro.viewed',
      submitted: 'tempo_signup_intro.submitted',
      signInClicked: 'tempo_signup_intro_signin.clicked',
      planClicked: 'tempo_signup_intro_plan.clicked',
      experienceClicked: 'tempo_signup_intro_experience.clicked',
    },
    preferences: {
      viewed: 'tempo_signup_preference.viewed',
      submitted: 'tempo_signup_preference.submitted',
      greatestHits: 'tempo_signup_preference_greatesthits.clicked',
      easyPrepMeals: 'tempo_signup_preference_easyprep.clicked',
      familyMeals: 'tempo_signup_preference_family.clicked',
      freshStart: 'tempo_signup_preference_freshstart.clicked ',
    },
    preferencesDietary: {
      viewed: 'tempo_preference_dietary.viewed',
      submitted: 'tempo_preference_dietary.submitted',
    },
    preferencesAvoidances: {
      viewed: 'tempo_preference_avoidances.viewed',
      submitted: 'tempo_preference_avoidances.submitted',
    },
    boxRecommender: {
      boxPeopleViewed: 'tempo_funnel.box_people.viewed',
      boxPeopleSubmitted: 'tempo_funnel.box_people.submitted',
      boxMealsViewed: 'tempo_funnel.box_meals.viewed',
      boxMealsSubmitted: 'tempo_funnel.box_meals.submitted',
      boxDaysViewed: 'tempo_funnel.box_days.viewed',
      boxDaysSubmitted: 'tempo_funnel.box_days.submitted',
    },
    box: {
      viewed: 'tempo_signup_box.viewed',
      submitted: 'tempo_signup_box.submitted',
    },
    promo: {
      newSignUpPromoViewed: 'tempo_signup_promo.viewed',
      newSignUpPromoSubmitted: 'tempo_signup_promo.submitted',
      newSignUpPromoApplied: 'tempo_signup_promo.applied',
      checkoutPromoApplied: 'tempo_signup_promo.checkout_applied',
      newSignUpPromoChanged: 'tempo_signup_promo.changed',
      newSignUpSecretSaleClaimed: 'tempo_signup_secretsale.claimed',
      newSignUpIdmeClicked: 'tempo_signup_idme.clicked',
    },
    checkout: {
      viewed: 'tempo_signup_billing.viewed',
      paypalClicked: 'tempo_signup_paypal.clicked',
      applepayClicked: 'tempo_signup_applepay.clicked',
      submitted: 'tempo_signup_billing_cta.submitted',
    },
    thankYou: {
      viewed: 'tempo_signup_thankyou.viewed',
      submitted: 'tempo_signup_thankyou.submitted',
      referralSent: 'tempo_signup_thankyou_referral.sent',
      referralClicked: 'tempo_signup_thankyou_referrallink.copied',
    },
    reactivate: {
      viewed: 'tempo_funnel_reactivate.viewed',
      submitted: 'tempo_funnel_reactivate.submitted',
    },
  },
};

const pickMeals = {
  tempo: {
    menu: {
      viewed: 'tempo.pickmeals.menu.viewed',
      submitted: 'tempo.pickmeals.menu.submitted',
    },
    promo: {
      checkoutPromoApplied: 'tempo.pickmeals.signup_promo.checkout_applied',
    },
    billing: {
      viewed: 'tempo.pickmeals.billing.viewed',
      submitted: 'tempo.pickmeals.billing.submitted',
      paypalClicked: 'tempo.pickmeals.signup_paypal.clicked',
      applepayClicked: 'tempo.pickmeals.signup_applepay.clicked',
    },
    thankYou: {
      viewed: 'tempo.pickmeals.thankyou.viewed',
      submitted: 'tempo.pickmeals.thankyou.submitted',
      referralSent: 'tempo.pickmeals.signup_thankyou_referral.sent',
      referralClicked: 'tempo.pickmeals.signup_thankyou_referrallink.copied',
    },
  },
};

const dashboard = {
  viewed: 'dashboard.viewed',
  shareInviteClicked: 'dashboard.referral.share_invite_clicked',
  detailsViewed: 'dashboard.referral.view_details_clicked',
  referralSent: 'dashboard.referral.sent',
  inviteCodeCopied: 'dashboard.pretty_invite_code.copy',
  weeklyMenuViewed: 'dashboard.weekly_menu.viewed',
};

const slideout = {
  addMealClicked: 'slideout.add_meal.clicked',
  removeMealClicked: 'slideout.remove_meal.clicked',
  customizeSubmitted: 'slideout.customize.submitted',
};

const editedBasket = {
  hostAndPairedAdded: 'suggested_pairing.pairing_added',
  hostAndPairedRemoved: 'suggested_pairing.pairing_removed',
  pairedAdded: 'suggested_pairing.extra_added',
  pairedRemoved: 'suggested_pairing.extra_removed',
  basketBehavioralDiscountDetailsViewClicked: 'weekly_menu.BD_details.viewed',
};

const orderSummary = {
  behavioralDiscountDetailsViewClicked: 'order_summary.BD_details.viewed',
};

const emptyBasketModal = {
  viewed: 'empty_basket_modal.viewed',
  clicked: 'empty_basket_modal.plan.clicked',
};

const accountVerify = {
  viewed: 'verify.viewed',
  editPayment: 'verify_edit_payment.clicked',
  editAddress: 'verify_edit_address.clicked',
  confirmed: 'verify_confirm.clicked',
  paymentViewed: 'verify_payment.viewed',
  paymentCancel: 'verify_payment_cancel.clicked',
  updatePayment: 'verify_payment_update.clicked',
  addressViewed: 'verify_address.viewed',
  addressCancel: 'verify_address_cancel.clicked',
  updateAddress: 'verify_address_update.clicked',
};

const offerRewards = {
  viewed: 'offer_center.viewed',
  offersMenuClicked: 'offer_center.your_offers.view_menu_clicked',
  referralSent: 'offer_center.referral.sent',
  inviteCodeCopied: 'offer_center.pretty_invite_code.copy',
  shareInviteClicked: 'offer_center.referral.share_invite_clicked',
  detailsViewed: 'offer_center.referral.view_details_clicked',
  resendEmailClicked: 'offer_center.perks.resend_email_clicked',
  voucherDetailsViewed: 'offer_center.voucher_details.viewed',
  voucherDetailsViewOrderClicked: 'offer_center.voucher_details.view_order_clicked',
  behavioralDiscountDetailsViewed: 'offer_center.BD_details.viewed',
  behavioralDiscountDetailsViewOrderClicked: 'offer_center.BD_details.view_order_clicked',
};

export const events = {
  accountSettings,
  accountVerify,
  betaBox,
  cookbook,
  confirmationModal,
  dashboard,
  editedBasket,
  emptyBasketModal,
  extras,
  holiday,
  meal,
  mealCard,
  mealDetails,
  mealsNavigation,
  menuSearch,
  messageCenter,
  modal,
  offerRewards,
  orderHistory,
  orderSummary,
  pause,
  pickMeals,
  popUp,
  quickFilters,
  shoppingList,
  signUp,
  slideout,
  subscriptionForm,
  tracking,
};

export const logEvent = (eventName, properties = {}) => {
  Amplitude.track({ type: Amplitude.ANALYTICS_EVENT, eventName }, decamelizeKeys(properties));
};
